body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color:#454545;

  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
}

h1,h2,h3,h4,h5,h6{ color:#232323;}

* {
  scrollbar-width: thin;
  scrollbar-color: #d0d0d0 #d1d1d136;
}
/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #5e5e8022;
}

::-webkit-scrollbar-thumb {
  background-color: #31313677;
  border-radius: 4px;
}
